import { render, staticRenderFns } from "./ContinueForm.vue?vue&type=template&id=88657e80&scoped=true&lang=pug&"
import script from "./ContinueForm.vue?vue&type=script&lang=js&"
export * from "./ContinueForm.vue?vue&type=script&lang=js&"
import style0 from "@platyplus/humanitarian-icons/dist/icons.css?vue&type=style&index=0&lang=css&"
import style1 from "./ContinueForm.vue?vue&type=style&index=1&id=88657e80&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "88657e80",
  null
  
)

export default component.exports
import QForm from 'quasar/src/components/form/QForm.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QLinearProgress from 'quasar/src/components/linear-progress/QLinearProgress.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QForm,QBtn,QLinearProgress});
